/**
 * Breakpoint module
 * - Returns the current media breakpoint
 */
;var breakpoint = (function (window, $) {
    /**
     * Breakpoint, returns the current breakpoint
     * @returns {string|*}
     */
    var breakpoint = function () {
        return $(document.body).pseudoProperty('after', 'content');
    };

    return breakpoint;
})(window, jQuery);

/**
 * Export for unit tests
 */
if ("object" == typeof module && "object" == typeof module.exports) {
    module.exports = breakpoint;
}

