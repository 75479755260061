/**
 * TOGGLES: A class that toggles toggleable elements with a toggleclass
 *
 * Put two arguments on the element that triggers the toggle:
 * - data-toggles = Target that will be toggled
 * - data-toggles-class = Class that will be appended, defaults to 'is--visible'
 *
 */

;(function ($, window) { // eslint-disable-line no-extra-semi
    'use strict';

    /**
     * Sticky component constructor
     * @param $element
     * @param targets
     * @param event
     * @param auto
     * @param breakpoint
     * @constructor
     */
    var Toggles = function ($element, targets, event, auto, breakpoint) {
        this._$el = $element;
        this._targets = targets;
        this._event = event;
        this._breakpoint = breakpoint;

        /**
         * Auto-toggle as soon as we hit an `auto` = true
         */
        if (auto === true) {
            return this.toggle();
        }

        this._$el.on('click', this.toggle.bind(this));
    };

    /**
     * Toggle the toggleClass on the target
     */
    Toggles.prototype.toggle = function () {
        if (
            this._breakpoint !== undefined &&
            this._$el.data('toggles-breakpoint') !== undefined &&
            this._$el.data('toggles-breakpoint').split(',').indexOf(this._breakpoint()) === -1
        ) {
            return true;
        }

        for (var selector in this._targets) {
            var $target = selector === 'this' ? this._$el : $(selector),
                className = this._targets[selector];

            if (className instanceof Array === true) {
                className.map(function(e) {
                    this._toggleElement($target, e);
                }.bind(this));
            } else {
                this._toggleElement($target, className);
            }
        }

        if (this._event !== undefined) {
            this._fireEvent(this._event);
        }
    };

    /**
     * Toggle a specific class on a target element
     * @param $target
     * @param className
     * @private
     */
    Toggles.prototype._toggleElement = function ($target, className) {
        $target.toggleClass(className);
    };

    /**
     * Fire the event on the window object
     * @param event
     * @private
     */
    Toggles.prototype._fireEvent = function (event) {
        $(window).trigger(event);
    };

    /**
     * Attach new toggle
     * @private
     */
    Toggles.prototype._attach = function () {
        var $el = $(this),
            targets = $el.data('toggles'),
            event = $el.data('toggles-event'),
            auto = $el[0].hasAttribute('data-toggles-auto') || false;

        new Toggles($el, targets, event, auto, window.breakpoint);
    };

    /**
     * Attach listeners to document
     */
    $(function () {
        /**
         * Travel through the DOM for data-at-modal attributes
         * For each of the founded data attr create a new modal
         */
        $('[data-toggles]').each(Toggles.prototype._attach);

        /**
         * Setup a jQuery chainable plugin for attaching to injected elements after page load
         * @type {*}
         */
        $.fn.AtToggles = function () {
            $(this).each(Toggles.prototype._attach);
        };
    });

})(jQuery, window);
